import React from 'react';
import LanguageCard from './LanguageCard';

const Home = (props) => {
  return (
    <div>
	<div>
	  	<br/>
      		<center><h1>Your Exciting Entertainment Hub!</h1></center>
	</div>

        <div style={{padding:50, display:'inline-flex', flexWrap:'wrap'}}>
	            <LanguageCard lang='Hindi' title='Hindi Movies' bgcolor='#454545'/>
	            <LanguageCard lang='Telugu' title='Telugu Movies' bgcolor='#454545'/>
	            <LanguageCard lang='Tamil' title='Tamil Movies' bgcolor='#454545'/>
	            <LanguageCard lang='Marathi' title='Marathi Movies' bgcolor='#454545'/>
	            <LanguageCard lang='Bengal' title='Bengal Movies' bgcolor='#454545'/>
	            <LanguageCard lang='English' title='English Movies' bgcolor='#454545'/>
	            <LanguageCard lang='Kannada' title='Kannada Movies' bgcolor='#454545' movie_lang='hindimovies'/>
	            <LanguageCard lang='Malayalam' title='Malayalam Movies' bgcolor='#454545'/>
	            <LanguageCard lang='Punjabi' title='Punjabi Movies' bgcolor='#454545'/>
	            <LanguageCard lang='Bhojpuri' title='Bhojpuri Movies' bgcolor='#454545'/>
	            <LanguageCard lang='Gujarati' title='Gujarati Movies' bgcolor='#454545'/>
	            <LanguageCard lang='Rajasthani' title='Rajasthani Movies' bgcolor='#454545'/>
        </div>
    </div>
  );
}

export default Home;

